const hljs = window.hljs;

const qqInterface = {
    mounted() {
        if (hljs) {
            hljs.initHighlighting.called = false;
            hljs.initHighlighting();
        }
    },
    computed: {
        lotteryCode() {
            throw 'Override this computed property:(lotteryCode) by component.';
        },
        apiUrl() {
            var protocol = window.location.protocol;
            var hostname = window.location.hostname;
            var port = window.location.port ? ':' + window.location.port : '';
            var url = [protocol, '//', hostname, port].join('');
            var path = ['api', 'lotto', this.lotteryCode, 'GetDataByNow'].join('/');

            return [url, path].join('/');
        }
    }
};

export default qqInterface;