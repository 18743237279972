<template>
  <div>
    <div class="banner_tencent">
      <div class="container">
        <div class="banner_content">
          <div class="online">
            <p><i class="icon-clock"></i>{{issueDateTime}}</p>
            <p>腾讯同时在线人数：</p>
          </div>
          <div class="divider"></div>
          <div class="number">
            <template v-if="grabData.length" v-for="(n, index) in onlineUserCount">
              <template v-if="n===','">
                <li class="comma"></li>
              </template>
              <template v-else>
                <li :class="(grabData.length - index) < 3 ? 'bg_blue':'bg_gray'">
                  <c-image :path="`new/number_${n}.png`"></c-image>
                </li>
              </template>
            </template>
            <template v-else>
              <li class="bg_gray">
                <c-image path="new/number_0.png"></c-image>
              </li>
              <li class="bg_gray">
                <c-image path="new/number_0.png"></c-image>
              </li>
              <li class="comma"></li>
              <li class="bg_gray">
                <c-image path="new/number_0.png"></c-image>
              </li>
              <li class="bg_gray">
                <c-image path="new/number_0.png"></c-image>
              </li>
              <li class="bg_gray">
                <c-image path="new/number_0.png"></c-image>
              </li>
              <li class="comma"></li>
              <li class="bg_blue">
                <c-image path="new/number_0.png"></c-image>
              </li>
              <li class="bg_blue">
                <c-image path="new/number_0.png"></c-image>
              </li>
              <li class="bg_blue">
                <c-image path="new/number_0.png"></c-image>
              </li>
            </template>
          </div>
          <div class="compare">
            <p>
              同比上期：
              {{formattedOnlineUserDiff}}
              <i :class="onlineUserDiff > -1 ? 'icon-dataup' : 'icon-datadown'"></i>
            </p>
            <p>历史最高在线人数：339,866,150</p>
          </div>
          <div class="remark">
            <p><i class="icon-bulb"></i>采集结果返回时间因腾讯本身原因略有波动，请以表格中为准</p>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs container">
      <div class="tab_group">
        <router-link class="tab" active-class="active" :to="{ name:'QQ_Statistics' }">统计结果</router-link>
        <router-link class="tab" active-class="active" :to="{ name:'QQ_Help' }">统计说明</router-link>
        <router-link class="tab" active-class="active" :to="{ name:'QQ_Interface' }">接口调用</router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { qqBanner } from '../../mixins';

export default {
  mixins: [qqBanner],
  computed: {
    lotteryId() {
      return 27;
    }
  }
}
</script>