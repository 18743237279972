import OMLHC from '../views/OMLHC';

const routes = [{
    path: '/OMLHC',
    name: 'OMLHC_Layout',
    component: OMLHC.Layout,
    redirect: {
        name: 'OMLHC_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過OMLHC沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'OMLHC_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'OMLHC_Statistics',
            component: OMLHC.Statistics
        },
        {
            path: 'Interface',
            name: 'OMLHC_Interface',
            component: OMLHC.Interface
        }
    ]
}];

export default routes;