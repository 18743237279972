import Image from './Image';
import Feature from './Feature';
import Search from './Search';
import Pager from './Pager';

export default {
    install: app => {
        // 'c-' stands for 'component-'
        app.component('c-image', Image);
        app.component('c-feature', Feature);
        app.component('c-search', Search);
        app.component('c-pager', Pager);
    }
};

