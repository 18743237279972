import BeijingPK10 from '../views/BeijingPK10';

const routes = [{
    path: '/BeijingPK10',
    name: 'BeijingPK10_Layout',
    component: BeijingPK10.Layout,
    redirect: {
        name: 'BeijingPK10_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過BeijingPK10沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'BeijingPK10_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'BeijingPK10_Statistics',
            component: BeijingPK10.Statistics
        },
        {
            path: 'Interface',
            name: 'BeijingPK10_Interface',
            component: BeijingPK10.Interface
        }
    ]
}];

export default routes;