<template>
  <div>
    <div class="filter container">
      <div class="filter_title fl">接口调用</div>
    </div>
    <div class="article container">
      <p>本网站提供数据rest api接口，接口地址：<a :href="apiUrl" target="_blank">{{apiUrl}}</a></p>
      <p>返回最近10分钟内的微信在线人数</p>
      <p>请求方式：get</p>
      <p>返回类型：json</p>
      <div class="help_article">
        <p>返回结果说明：</p>
        <pre><code class="language-json">
    {
        "Success": true,
        "Code": "200",
        "Message": "success",
        "Result": [
            {
                "IssueNo": 1906211151,
                "IssueTime": "2019-06-21 11:51:00",
                "LottorNums": "3,0,5,6,0",
                "OnlineNumber": 317650560,
                "OnlineChange": 90425
            },
        ]
    }</code></pre>
      </div>
      <div class="help_article">
        <p>说明:</p>
        <ol>
          <li>IssueNo: 期号</li>
          <li>IssueTime: 开奖时间</li>
          <li>OnlineNumber: 微信在线人数</li>
          <li>OnlineChange: 微信在线人数变化值</li>
          <li>LottorNums: 开奖结果</li>
        </ol>
      </div>
    </div>
  </div>
</template>


<script>
import { apiInterface } from '../../mixins';

export default {
  mixins: [apiInterface],
  computed: {
    lotteryCode() {
      return 'WeixinQQ';
    }
  }
}
</script>