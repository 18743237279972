import service from '../service';
import event from '../event';

const banner = {
    data() {
        return {
            currentIssue: {
                currentIssueNo: '',
                currentIssueDateTime: '',
                nextIssueNo: '',
                seconds: 0,
                drawNumbers: [],
                grabData: '',
                prevIssueNo: '',
                prevIssueDateTime: '',
                prevDrawNumbers: [],
                prevGrabData: '',
                prev2IssueNo: '',
                prev2IssueDateTime: '', prev2DrawNumbers: [],
                prev2GrabData: ''
            }
        };
    },
    methods: {
        async getCurrentIssueAsync() {
            this.currentIssue = await service.getCurrentIssueAsync(this.lotteryId);
        }
    },
    created() {
        this.getCurrentIssueAsync();

        event.receive(event.eventNames.refresh, this.getCurrentIssueAsync);
    },
    beforeDestroy() {
        event.unsubscribe(event.eventNames.refresh, this.getCurrentIssueAsync);
    },
    computed: {
        issueDateTime() {
            var formatter = 'YYYY-MM-DD HH:mm:ss';
            var date = this.currentIssue.grabData ? this.currentIssue.currentIssueDateTime : this.currentIssue.prevIssueDateTime;

            return this.$formatDateTime(date, formatter);
        },
        grabData() {
            return (this.currentIssue.grabData || this.currentIssue.prevGrabData).split('');
        },
        lotteryId() {
            throw 'Override this computed property:(lotteryId) by component.';
        }
    }
};

export default banner;