import OMSSC from '../views/OMSSC';

const routes = [{
    path: '/OMSSC',
    name: 'OMSSC_Layout',
    component: OMSSC.Layout,
    redirect: {
        name: 'OMSSC_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過OMSSC沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'OMSSC_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'OMSSC_Statistics',
            component: OMSSC.Statistics
        },
        {
            path: 'Interface',
            name: 'OMSSC_Interface',
            component: OMSSC.Interface
        }
    ]
}];

export default routes;