<template>
  <div>
    <div class="filter container">
      <div class="filter_title fl">接口调用</div>
    </div>
    <div class="article container">
      <p>本网站提供数据rest api接口，接口地址：<a :href="apiUrl" target="_blank">{{apiUrl}}</a></p>      
      <p>请求方式：get</p>
      <p>返回类型：json</p>
      <div class="help_article">
        <p>返回结果说明：</p>
        <pre><code class="language-json">
    {
        "Code": "200",
        "Message": "success",
        "Success": true,
        "Result": [
            {
                "IssueNo": 21070225,
                "IssueTime": "2021-07-02 17:01:00",
                "LottorNums": "02,07,11,01,05"
            }
        ]
    }
</code></pre>
      </div>
      <div class="help_article">
        <p>说明:</p>
        <ol>
          <li>IssueNo: 期号</li>
          <li>IssueTime: 开奖时间</li>
          <li>LottorNums: 开奖结果</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import { apiInterface } from '../../mixins';

export default {
  mixins: [apiInterface],
  computed: {
    lotteryCode() {
      return 'SDSelectFive';
    }
  }
}
</script>