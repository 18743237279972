import Vue from 'vue';
import VueRouter from 'vue-router';
import QQ from './QQ';
import MQQ from './MQQ';
import WeixinQQ from './WeixinQQ';
import SDSelectFive from './SDSelectFive';
import Jx11x5 from './Jx11x5';
import GDSelectFive from './GDSelectFive';
import BeijingPK10 from './BeijingPK10';
import ChongQing from './ChongQing';
import OMKS from './OMKS';
import OMPK10 from './OMPK10';
import OMSSC from './OMSSC';
import OMLHC from './OMLHC';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: {
      name: 'QQ_Statistics'
    }
  },
  ...QQ,
  ...MQQ,
  ...WeixinQQ,
  ...SDSelectFive,
  ...Jx11x5,
  ...GDSelectFive,
  ...BeijingPK10,
  ...ChongQing,
  ...OMKS,
  ...OMPK10,
  ...OMSSC,
  ...OMLHC,
  {
    path: "*",
    redirect: {
      name: 'QQ_Statistics'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
