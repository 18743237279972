<template>
    <div>
      <c-search :querys="querys" title="一分时时彩开奖结果" @search="getStatisticsAsync"></c-search>
      <div class="container">
        <div class="history qq_racing_history">
          <div class="ball_11pick5 scroll_11pick5">
            <div class="title">
                <li>序号</li>
                <li>采集返回时间</li>
                <li>开奖结果</li>
            </div>
            <div class="list" v-for="data in statistics">
              <li>{{data.issueNo}}</li>
              <li>{{data.issueEndTime | formatDateTime('YYYY-MM-DD HH:mm:ss')}}</li>
              <li>
                <div v-for="n in data.drawNumbers" class="ball_win_num">{{n}}</div>
              </li>
            </div>
          </div>
        </div>
        <c-pager v-model="pagination.pageNumber" :total="pagination.totalCount" :pageSize="pagination.pageSize" @change="pageChange"></c-pager>
        <c-feature></c-feature>
      </div>
    </div>
  </template>
  
  <script>
  import { statistics } from '../../mixins';
  
  export default {
    mixins: [statistics],
    computed: {
      lotteryId() {
        return 67;
      }
    }
  }
  </script>