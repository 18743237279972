import ChongQing from '../views/ChongQing';

const routes = [{
    path: '/ChongQing',
    name: 'ChongQing_Layout',
    component: ChongQing.Layout,
    redirect: {
        name: 'ChongQing_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過ChongQing沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'ChongQing_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'ChongQing_Statistics',
            component: ChongQing.Statistics
        },
        {
            path: 'Interface',
            name: 'ChongQing_Interface',
            component: ChongQing.Interface
        }
    ]
}];

export default routes;