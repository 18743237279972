const layout_11x5 = {
    computed: {
        issueNo() {
            return this.currentIssue.drawNumbers.length ? this.currentIssue.currentIssueNo : this.currentIssue.prevIssueNo;
        },
        drawNumbers() {
            if (this.currentIssue.drawNumbers.length)
                return this.currentIssue.drawNumbers;

            if (this.currentIssue.prevDrawNumbers.length)
                return this.currentIssue.prevDrawNumbers;

            return ['', '', '', '', ''];
        },
    }
};

export default layout_11x5;