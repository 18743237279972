import SDSelectFive from '../views/SDSelectFive';

const routes = [{
    path: '/SDSelectFive',
    name: 'SDSelectFive_Layout',
    component: SDSelectFive.Layout,
    redirect: {
        name: 'SDSelectFive_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過SDSelectFive沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'SDSelectFive_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'SDSelectFive_Statistics',
            component: SDSelectFive.Statistics
        },
        {
            path: 'Interface',
            name: 'SDSelectFive_Interface',
            component: SDSelectFive.Interface
        }
    ]
}];

export default routes;