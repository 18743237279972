import Vue from 'vue';

const eventNames = Object.freeze({
    refresh: 'EVENT_REFRESH',
    showLoading: 'EVENT_SHOWLOADING',
    hideLoading: 'EVENT_HIDELOADING'
});

const eventBus = new Vue();

const emit = (eventName, ...args) => eventBus.$emit(eventName, ...args);
const receive = (eventName, callbackFn) => eventBus.$on(eventName, callbackFn);
const unsubscribe = (eventName, callbackFn) => eventBus.$off(eventName, callbackFn);

export default { eventNames, emit, receive, unsubscribe }