<template>
    <div>
      <c-search :querys="querys" title="一分快车开奖结果" @search="getStatisticsAsync"></c-search>
      
      <div class="container">
        <div class="history qq_racing_history">
            <div class="ompk10 scroll_ompk10">
                <div class="title">
                    <li>序号</li>
                    <li>采集返回时间</li>
                    <li>奖号</li>
                </div>
                <div class="list" v-for="data in statistics">
                  <li>{{data.issueNo}}</li>
                  <li>{{data.issueEndTime | formatDateTime('YYYY-MM-DD HH:mm:ss')}}</li>
                  <li>
                    <div v-for="n in data.drawNumbers" :class="`win_num bg${n}`">{{n}}</div>
                  </li>
                </div>                
            </div>
        </div>

        <c-pager v-model="pagination.pageNumber" :total="pagination.totalCount" :pageSize="pagination.pageSize" @change="pageChange"></c-pager>
        <c-feature></c-feature>
      </div>
    </div>
  </template>
  
  <script>
  import { statistics } from '../../mixins';
  
  export default {
    mixins: [statistics],
    computed: {
      lotteryId() {
        return 66;
      }
    }
  }
  </script>