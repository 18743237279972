<template>
    <div>
      <div class="banner_setting banner_pk10_yf">
        <div class="container">
            <div class="banner_content">
               <div class="online">
                <h1>一分快车</h1>
                  <p><i class="icon-clock"></i>{{issueDateTime}}</p>
                  <p>最新开奖期号{{issueNo}}</p>
               </div>
               <div class="divider"></div>
               <div class="racing_num">
                <li v-for="n in drawNumbers" :class="`bg_racing_${n}`">{{n}}</li>
               </div>
            </div>
        </div>
    </div>

      <div class="tabs container">
        <div class="tab_group">
          <router-link class="tab" active-class="active" :to="{ name:'OMPK10_Statistics' }">统计结果</router-link>
          <router-link class="tab" active-class="active" :to="{ name:'OMPK10_Interface' }">接口调用</router-link>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import { banner, layout_ssc } from '../../mixins';
  
  export default {
    mixins: [banner, layout_ssc],
    computed: {
      lotteryId() {
        return 66;
      }
    }
  }
  </script>