<template>
  <img :src="dynamicSrc">
</template>

<script>

export default {
  props: {
    path: {
      type: String,
      required: true
    }
  },
  computed: {
    dynamicSrc() {
      try {
        return require(`../assets/images/${this.path}`);
      } catch (error) {
        console.warn(error);
        return '';
      }
    }
  }
}
</script>
