<template>
  <div>
    <div class="feature container">
      <div class="feature_title">
        <div class="feature_text"><span>产品特点</span></div>
      </div>
      <div class="feature_list">
        <div class="item">
          <div class="icon">
            <c-image path="new/product_01.png"></c-image>
          </div>
          <div class="text">
            <p>腾讯大数据</p>
            <p>提供可靠实时数据</p>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <c-image path="new/product_02.png"></c-image>
          </div>
          <div class="text">
            <p>极速采集</p>
            <p>1分钟1次，24小时不间断</p>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <c-image path="new/product_03.png"></c-image>
          </div>
          <div class="text">
            <p>精准查询</p>
            <p>最小支持到分钟</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>