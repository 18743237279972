<template>
  <div>
    <!-- header *** start -->
    <header>
      <div class="container no_gap">
        <div class="flex_header">
          <div class="logo">
            <router-link :to="{ name:'Home' }"></router-link>
          </div>
          <div class="main_btn_content">
            <div class="main_btn">
              <router-link active-class="active" :to="{ name:'QQ_Layout' }">腾讯在线统计</router-link>
              <router-link active-class="active" :to="{ name:'MQQ_Layout' }">QQ在线统计</router-link>
              <router-link active-class="active" :to="{ name:'WeixinQQ_Layout' }">微信在线统计</router-link>
              <div class="dropdown_content">
                <a class="dropdown_btn">十一选五</a>
                <div class="dropdown_menu">
                  <div class="container">
                    <ul>
                      <li>
                        <router-link active-class="active" :to="{ name:'Jx11x5_Layout' }">江西十一选五</router-link>
                      </li>
                      <li>
                        <router-link active-class="active" :to="{ name:'SDSelectFive_Layout' }">山东十一选五</router-link>
                      </li>
                      <li>
                        <router-link active-class="active" :to="{ name:'GDSelectFive_Layout' }">广东十一选五</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="dropdown_content">
                <a class="dropdown_btn">PK10</a>
                <div class="dropdown_menu">
                  <div class="container">
                    <ul>
                      <li>
                        <router-link active-class="active" :to="{ name:'BeijingPK10_Layout' }">北京PK10</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="dropdown_content">
                <a class="dropdown_btn">時時彩</a>
                <div class="dropdown_menu">
                  <div class="container">
                    <ul>
                      <li>
                        <router-link active-class="active" :to="{ name:'ChongQing_Layout' }">重庆时时彩</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="dropdown_content">
                <a class="dropdown_btn">一分彩</a>
                <div class="dropdown_menu">
                  <div class="container">
                    <ul>
                      <li>
                        <router-link active-class="active" :to="{ name:'OMKS_Layout' }">一分快三</router-link>
                      </li>
                      <li>
                        <router-link active-class="active" :to="{ name:'OMPK10_Layout' }">一分快车</router-link>
                      </li>
                      <li>
                        <router-link active-class="active" :to="{ name:'OMSSC_Layout' }">一分时时彩</router-link>
                      </li>
                      <li>
                        <router-link active-class="active" :to="{ name:'OMLHC_Layout' }">一分六合彩</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- mobile btn -->
            <div class="main_btn mobile">
              <router-link active-class="active" :to="{ name:'QQ_Layout' }">腾讯在线统计</router-link>
              <router-link active-class="active" :to="{ name:'MQQ_Layout' }">QQ在线统计</router-link>
              <router-link active-class="active" :to="{ name:'WeixinQQ_Layout' }">微信在线统计</router-link>
              <router-link active-class="active" :to="{ name:'Jx11x5_Layout' }">江西十一选五</router-link>
              <router-link active-class="active" :to="{ name:'SDSelectFive_Layout' }">山东十一选五</router-link>
              <router-link active-class="active" :to="{ name:'GDSelectFive_Layout' }">广东十一选五</router-link>
              <router-link active-class="active" :to="{ name:'BeijingPK10_Layout' }">北京PK10</router-link>
              <router-link active-class="active" :to="{ name:'ChongQing_Layout' }">重庆时时彩</router-link>
              <router-link active-class="active" :to="{ name:'OMKS_Layout' }">一分快三</router-link>
              <router-link active-class="active" :to="{ name:'OMPK10_Layout' }">一分快车</router-link>
              <router-link active-class="active" :to="{ name:'OMSSC_Layout' }">一分时时彩</router-link>
              <router-link active-class="active" :to="{ name:'OMLHC_Layout' }">一分六合彩</router-link>              
            </div>
            <!-- mobile btn end -->
          </div>
        </div>
        <div class="mobile_menu_control" @click="togglesMobileMenuContentVisible">
          <c-image path="new/mobile_menu_control.svg"></c-image>
        </div>
      </div>
    </header>
    <!-- header *** end -->
    <router-view></router-view>
    <!-- mobile header menu *** start -->
    <div class="mobile_menu_content" :class="{'show': showMobileMenuContent}">
      <div class="list">
        <ul>
          <li>
            <router-link active-class="active" :to="{ name:'QQ_Layout' }">腾讯在线统计</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'MQQ_Layout' }">QQ在线统计</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'WeixinQQ_Layout' }">微信在线统计</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'Jx11x5_Layout' }">江西十一选五</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'SDSelectFive_Layout' }">山东十一选五</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'GDSelectFive_Layout' }">广东十一选五</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'BeijingPK10_Layout' }">北京PK10</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'ChongQing_Layout' }">重庆时时彩</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'OMKS_Layout' }">一分快三</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'OMPK10_Layout' }">一分快车</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'OMSSC_Layout' }">一分时时彩</router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name:'OMLHC_Layout' }">一分六合彩</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- mobile header menu *** end -->

    <!-- footer *** start -->
    <div class="footer">
      <div class="container">
        <div class="groups">
          <div class="support">
            <p>技术支持</p>
            <c-image path="new/footer_machine.png" alt="技术支持"></c-image>
          </div>
          <div class="related">
            <p>产品相关</p>
            <c-image path="new/footer_related.png" alt="产品相关"></c-image>
          </div>
          <div class="links">
            <p>帮助</p>
            <div>
              <router-link to="Statistics">在线统计</router-link>
              <router-link to="Interface">接口调用</router-link>
              <router-link to="Help">统计说明</router-link>
            </div>
          </div>
          <div class="links">
            <p>友情链接</p>
            <div>
              <a href="https://im.qq.com/pcqq" target="_blank" class="">QQ官网</a>
              <a href="http://browser.qq.com/" target="_blank" class="">QQ浏览器</a>
            </div>
          </div>
        </div>
        <div class="copyright">
          <p>Copyright © 1998- 2019 Tencent. All Rights Reserved.</p>
          <p>力胜科技 版权所有</p>
        </div>
      </div>
    </div>
    <div class="loader_wrapper" v-if="loadingVisible">
      <div class="loader_bg">
        <div class="loader">
          <div class="loader_animate"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// CSS
import './assets/css/reset.css';
import './assets/css/style.css';
import './assets/css/icons.css';

import event from './event';

export default {
  watch: {
    $route() {
      this.showMobileMenuContent = false;
    }
  },
  data() {
    return {
      showMobileMenuContent: false,
      loadingVisible: false
    };
  },
  methods: {
    togglesMobileMenuContentVisible() {
      this.showMobileMenuContent = !this.showMobileMenuContent;
    },
    showLoading() {
      this.loadingVisible = true;
    },
    hideLoading() {
      this.loadingVisible = false;
    }
  },
  created() {
    event.receive(event.eventNames.showLoading, this.showLoading);
    event.receive(event.eventNames.hideLoading, this.hideLoading);
  },
  beforeDestroy() {
    event.unsubscribe(event.eventNames.showLoading, this.showLoading);
    event.unsubscribe(event.eventNames.hideLoading, this.hideLoading);
  }
};
</script>