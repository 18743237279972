import service from '../service';
import statistics from './statistics';

const qqStatistics = {
    mixins: [statistics],
    computed: {
        statisticsAsyncHandler() {
            // Override if necessary
            return service.getJiGuangTjStatisticsAsync;
        }
    }    
};

export default qqStatistics;