var moment = require('moment-timezone');

const timeZone = 'Asia/Shanghai';
const formatDateTime = (value, formatter) => (value && moment.tz(value, timeZone).format(formatter)) || '';
const formatDigits = value => (value && value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")) || '0';

const install = (Vue, options) => {
    Vue.prototype.$momentTz = value => moment.tz(value, timeZone);

    Vue.prototype.$formatDigits = formatDigits;

    Vue.prototype.$formatDateTime = formatDateTime;

    Vue.filter('formatDigits', formatDigits);

    Vue.filter('formatDateTime', formatDateTime);
};

export default install;