<template>
  <div>
    <div class="banner_setting banner_ssc_sf">
      <div class="container">
        <div class="banner_content">
          <div class="online">
            <h1>一分六合彩</h1>
            <p><i class="icon-clock"></i>{{ issueDateTime }}</p>
            <p>最新开奖期号{{ issueNo }}</p>
          </div>
          <div class="divider"></div>
          <div class="six_num">
            <li v-for="n in currentDrawNumber(drawNumbers)" :class="`bg_six_${getColorClassName(n)}`">
              <span>{{ n }}</span>
            </li>
            <li class="bg_six_plus"><span></span></li>
            <li :class="`bg_six_${getSpecialColorClassName(drawNumbers)}`">
              <span>{{ specialDrawNumber(drawNumbers) }}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs container">
      <div class="tab_group">
        <router-link
          class="tab"
          active-class="active"
          :to="{ name: 'OMLHC_Statistics' }"
          >统计结果</router-link
        >
        <router-link
          class="tab"
          active-class="active"
          :to="{ name: 'OMLHC_Interface' }"
          >接口调用</router-link
        >
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { banner, layout_ssc, statistics } from "../../mixins";

export default {
  mixins: [banner, layout_ssc, statistics],
  computed: {
    lotteryId() {
      return 68;
    },
  },
};
</script>
