import OMKS from '../views/OMKS';

const routes = [{
    path: '/OMKS',
    name: 'OMKS_Layout',
    component: OMKS.Layout,
    redirect: {
        name: 'OMKS_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過OMKS沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'OMKS_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'OMKS_Statistics',
            component: OMKS.Statistics
        },
        {
            path: 'Interface',
            name: 'OMKS_Interface',
            component: OMKS.Interface
        }
    ]
}];

export default routes;