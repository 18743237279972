import GDSelectFive from '../views/GDSelectFive';

const routes = [{
    path: '/GDSelectFive',
    name: 'GDSelectFive_Layout',
    component: GDSelectFive.Layout,
    redirect: {
        name: 'GDSelectFive_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過GDSelectFive沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'GDSelectFive_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'GDSelectFive_Statistics',
            component: GDSelectFive.Statistics
        },
        {
            path: 'Interface',
            name: 'GDSelectFive_Interface',
            component: GDSelectFive.Interface
        }
    ]
}];

export default routes;