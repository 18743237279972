import Vue from 'vue';
import App from './App.vue';
import router from './router';
import components from './components';
import i18n from './i18n';

Vue.config.productionTip = false;
Vue.use(components);
Vue.use(i18n);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
