<template>
  <div class="pagination" v-if="total">
    <div class="tips catch_time">共<span>{{total}}</span>条</div>
    <div class="nums_box">
      <div class="page_col">
        <a v-if="activePrev" href="javascript:void(0);" class="page_icon" @click="goToPage(1)">
          <i class="icon-pagefirst"></i>
        </a>
        <a v-if="activePrev" href="javascript:void(0);" class="page_icon" @click="goToPrev">
          <i class="icon-pageprev"></i>
        </a>
        <template v-for="p in itemsPerPage">
          <a href="javascript:void(0);" class="num page_num" :class="{'active':getPagerItemNumber(p)===value}" v-if="getPagerItemNumber(p)<=totalPages" @click="goToPage(getPagerItemNumber(p))">
            {{getPagerItemNumber(p)}}
          </a>
        </template>
        <span class="page_located">{{value}}</span>
        <a v-if="activeNext" href="javascript:void(0);" class="page_icon" @click="goToNext">
          <i class="icon-pagenext"></i>
        </a>
        <a v-if="activeNext" href="javascript:void(0);" class="page_icon" @click="goToPage(totalPages)">
          <i class="icon-pagelast"></i>
        </a>
      </div>
      <div class="page_col">
        <span>跳转至</span>
        <input v-model.number="jumpPageNumber" type="text" @change="goToPage(jumpPageNumber)">
        <span>页</span>
        <span class="page_total">共 {{totalPages}} 页</span>
      </div>
    </div>
    <div class="tips catch_time">采集时间为每分钟第00秒</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      itemsPerPage: 5,
      jumpPageNumber: null
    };
  },
  methods: {
    getPagerItemNumber(number) {
      return this.pageBlock * this.itemsPerPage + number;
    },
    goToPage(pageNumber) {
      if (this.value === pageNumber) return;


      if (isNaN(parseInt(pageNumber)) || parseInt(pageNumber) > this.totalPages || parseInt(pageNumber) < 1) {
        alert(`最多到第${this.totalPages}页`);
        return;
      }

      this.emitEvent(pageNumber);
    },
    goToPrev() {
      if (!this.activePrev) return;
      this.goToPage(this.value - 1);
    },
    goToNext() {
      if (!this.activeNext) return;
      this.goToPage(this.value + 1);
    },
    emitEvent(pageNumber) {
      this.$emit('input', pageNumber);
      this.$emit('change', pageNumber);
    }
  },
  computed: {
    activePrev() {
      return this.value > 1;
    },
    activeNext() {
      return this.value < this.totalPages;
    },
    totalPages() {
      return Math.floor((this.total + this.pageSize - 1) / this.pageSize);
    },
    pageBlock() {
      return Math.floor((this.value - 1) / this.itemsPerPage);
    }
  }
};
</script>