<template>
  <div>
    <div class="banner_pk10">
      <div class="container">
        <div class="banner_content">
          <div class="online">
            <p><i class="icon-clock"></i>{{issueDateTime}}</p>
            <p>最新开奖期号{{issueNo}}</p>
          </div>
          <div class="divider"></div>
          <div class="racing_num">
            <li class="bg_racing_01" v-for="n in drawNumbers" :class="'bg_racing_' + n">
              <span>{{n}}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs container">
      <div class="tab_group">
        <router-link class="tab" active-class="active" :to="{ name:'BeijingPK10_Statistics' }">统计结果</router-link>
        <router-link class="tab" active-class="active" :to="{ name:'BeijingPK10_Interface' }">接口调用</router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { banner } from '../../mixins';

export default {
  mixins: [banner],
  computed: {
    issueNo() {
      return this.currentIssue.drawNumbers.length ? this.currentIssue.currentIssueNo : this.currentIssue.prevIssueNo;
    },
    drawNumbers() {
      if (this.currentIssue.drawNumbers.length)
        return this.currentIssue.drawNumbers;

      if (this.currentIssue.prevDrawNumbers.length)
        return this.currentIssue.prevDrawNumbers;

      return ['', '', '', '', '', '', '', '', '', ''];
    },
    lotteryId() {
      return 12;
    }
  }

}
</script>