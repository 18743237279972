import banner from './banner';

const qqBanner = {
    mixins: [banner],
    computed: {
        onlineUserCount() {
            return this.$formatDigits(this.grabData.join('')).split('');
        },
        onlineUserDiff() {
            var current = this.currentIssue.grabData || this.currentIssue.prevGrabData;
            var prev = this.currentIssue.grabData ? this.currentIssue.prevGrabData : this.currentIssue.prev2GrabData;

            return parseInt(current) - parseInt(prev);
        },
        formattedOnlineUserDiff() {
            var diff = this.onlineUserDiff;
            var prefixSymbol = diff > -1 ? '+' : ''
            var digits = this.$formatDigits(diff);

            return prefixSymbol + digits;
        }
    }
};

export default qqBanner;