<template>
  <div>
    <div class="filter container">
      <div class="filter_title fl">统计说明</div>
    </div>
    <div class="article container">
      <h1>背景</h1>
      <p>因QQ官网无法方便的查看在线人数变化的历史记录， 故设立本站，以便玩家查询分析。</p>
      <h1>数据来源</h1>
      <p>腾讯QQ官网：<a href="https://im.qq.com/mobileqq/touch/" target="_blank">https://im.qq.com/mobileqq/touch/</a></p>
      <c-image path="new/qq_about.jpg"></c-image>
    </div>
  </div>
</template>

<script>
export default {

}
</script>