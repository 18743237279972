<template>
  <div>
    <div class="banner_setting banner_11pick5_gd">
      <div class="container">
        <div class="banner_content">
          <div class="online">
            <h1>广东十一选五</h1>
            <p><i class="icon-clock"></i>{{issueDateTime}}</p>
            <p>最新开奖期号{{issueNo}}</p>
          </div>
          <div class="divider"></div>
          <div class="ball_num">
            <li v-for="n in drawNumbers">
              <span>{{n}}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs container">
      <div class="tab_group">
        <router-link class="tab" active-class="active" :to="{ name:'GDSelectFive_Statistics' }">统计结果</router-link>
        <router-link class="tab" active-class="active" :to="{ name:'GDSelectFive_Interface' }">接口调用</router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { banner, layout_11x5 } from '../../mixins';

export default {
  mixins: [banner, layout_11x5],
  computed: {
    lotteryId() {
      return 6;
    }
  }
}
</script>