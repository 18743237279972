<template>
  <div class="container">
    <div class="filter">
      <div class="filter_title">
        {{title}}
      </div>
      <div class="filter_btn">
        <transition name="fade">
          <div class="input_group" v-if="searchFormVisible">
            <form>
              <div class="form_group formTag">
                <input type="text" v-model.number="querys.year">
                <label for="year">年</label>
                <input type="text" v-model.number="querys.month">
                <label for="month">月</label>
                <input type="text" v-model.number="querys.day">
                <label for="date">日</label>
              </div>
              <div class="form_group formTag">
                <input type="text" class="time" v-model.number="querys.hourStart">
                <label for="hour">时</label>
                <input type="text" class="time" v-model.number="querys.minuteStart">
                <label for="minute">分</label>
                <span>─</span>
                <input type="text" class="time" v-model.number="querys.hourEnd">
                <label for="hour">时</label>
                <input type="text" class="time" v-model.number="querys.minuteEnd">
                <label for="minute">分</label>
              </div>
              <div class="form_group formTag">
                <a href="javascript:void(0);" class="search" @click="search">查询</a>
              </div>
            </form>
          </div>
        </transition>
        <a href="javascript:void(0)" class="btn_search" @click="searchFormVisible = !searchFormVisible"><i class="icon-search"></i>高级搜寻</a>
        <a href="javascript:void(0)" class="btn_refresh" @click="refresh"><i class="icon-refresh"></i>刷新</a>
      </div>
    </div>
  </div>
</template>

<script>
import event from '../event';

export default {
  data() {
    return {
      searchFormVisible: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    querys: {
      type: Object
    }
  },
  methods: {
    search() {
      this.$emit('search');
    },
    refresh() {
      this.searchFormVisible = false;
      event.emit(event.eventNames.refresh);
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>