import MQQ from '../views/MQQ';

const routes = [{
    path: '/MQQ',
    name: 'MQQ_Layout',
    component: MQQ.Layout,
    redirect: {
        name: 'MQQ_Statistics'
    },
    children: [
        {
            path: 'Statistics',
            name: 'MQQ_Statistics',
            component: MQQ.Statistics
        },
        {
            path: 'Help',
            name: 'MQQ_Help',
            component: MQQ.Help
        },
        {
            path: 'Interface',
            name: 'MQQ_Interface',
            component: MQQ.Interface
        }
    ]
}];

export default routes;