import service from '../service';
import event from '../event';

const statistics = {
    data() {
        return {
            statistics: [],
            querys: {
                year: 0,
                month: 0,
                day: 0,
                hourStart: 0,
                minuteStart: 0,
                hourEnd: 0,
                minuteEnd: 0
            },
            pagination: {
                pageNumber: 1,
                pageSize: 60,
                totalCount: 0
            }
        };
    },
    methods: {
        refresh() {
            this.initQuerys();
            this.initPagination();
            this.getStatisticsAsync();
        },
        initQuerys() {
            var start = this.$momentTz().subtract(1, 'hour');

            var year = start.year();
            var month = parseInt(start.format('MM')); // .month()會傳0~11所以改用格式化
            var day = start.date();
            var hourStart = start.hours();
            var minuteStart = start.minutes();

            var end = this.$momentTz();
            var hourEnd = end.hours();
            var minuteEnd = end.minutes();

            this.querys = {
                year,
                month,
                day,
                hourStart,
                minuteStart,
                hourEnd,
                minuteEnd
            };
        },
        initPagination() {
            this.pagination = {
                pageNumber: 1,
                pageSize: 30,
                totalCount: 0
            };
        },
        validate() {
            // 20210630 驗證方式比照原本極光，要套lib以後再說
            if (Object.values(this.querys).some(x => isNaN(parseInt(x)) || parseInt(x) < 0)) {
                alert('时间栏位不得小于0');
                return false;
            }

            var nowYear = this.$momentTz().year();

            if (parseInt(this.querys.year) > nowYear) {
                alert(`最多到${nowYear}年`);
                return false;
            }

            var maxMonth = 12;

            if (parseInt(this.querys.month) > maxMonth) {
                alert(`最多到${maxMonth}月`);
                return false;
            }

            var lastDateOfMonth = this.$momentTz([this.querys.year, parseInt(this.querys.month) - 1]).daysInMonth();

            if (parseInt(this.querys.day) > lastDateOfMonth) {
                alert(`日期最多${lastDateOfMonth}日`);
                return false;
            }

            var maxHour = 24;
            if (parseInt(this.querys.hourStart) > maxHour || parseInt(this.querys.hourEnd) > maxHour) {
                alert(`小时不得​​大于${maxHour}小时`);
                return false;
            }

            var maxMinute = 59;
            if (parseInt(this.querys.minuteStart) > maxMinute || parseInt(this.querys.minuteEnd) > maxMinute) {
                alert(`分钟不得大于${maxMinute}分`);
                return false;
            }

            var start = this.$momentTz([this.querys.year, parseInt(this.querys.month) - 1, this.querys.day, this.querys.hourStart, this.querys.minuteStart]);
            var end = this.$momentTz([this.querys.year, parseInt(this.querys.month) - 1, this.querys.day, this.querys.hourEnd, this.querys.minuteEnd]);

            if (end.isBefore(start)) {
                alert('开始时间不得大于结束时间');
                return false;
            }

            var diff = end.diff(start, 'minute');
            var maxMinuteDiff = 60;
            if (diff > maxMinuteDiff) {
                alert('开始时间与结束时间间隔请在60分钟以内');
                return false;
            }

            return true;
        },
        async getStatisticsAsync() {
            if (!this.validate())
                return;

            var beginDate = this.$momentTz([this.querys.year, this.querys.month - 1, this.querys.day, this.querys.hourStart, this.querys.minuteStart])
                .utc().format();
            var endDate = this.$momentTz([this.querys.year, this.querys.month - 1, this.querys.day, this.querys.hourEnd, this.querys.minuteEnd, 59])
                .utc().format();
            var limit = this.pagination.pageSize;
            var skip = (this.pagination.pageNumber - 1) * this.pagination.pageSize;

            event.emit(event.eventNames.showLoading);
            var result = await this.statisticsAsyncHandler(this.lotteryId, beginDate, endDate, limit, skip);
            this.pagination.totalCount = result.totalCount;
            this.statistics = result.collection;
            event.emit(event.eventNames.hideLoading);
        },
        pageChange() {
            this.getStatisticsAsync();
        },
        getColorClassName(drawNumber) {      
            let mapping = this.sumColorClassNameMappings.filter(x => x.numbers.indexOf(drawNumber) > -1)[0];            
            return (mapping && mapping.className) || '';
        },
        getSpecialColorClassName(drawNumbers){
            const number = this.specialDrawNumber(drawNumbers);
            return this.getColorClassName(number);
        },
        currentDrawNumber(drawNumbers) {
            return drawNumbers.slice(0, 6);
        },
        specialDrawNumber(drawNumbers) {
            return drawNumbers.slice(-1)[0];
        },
        addPlusMark(drawNumbers){
            if(!drawNumbers || !Array.isArray(drawNumbers) || drawNumbers.lenght === 0 ) {
                return [];
            }
            drawNumbers.splice((drawNumbers.lenght - 1), 0, "");
            return drawNumbers;
        },        
    },
    async created() {
        this.refresh();

        event.receive(event.eventNames.refresh, this.refresh);
    },
    beforeDestroy() {
        event.unsubscribe(event.eventNames.refresh, this.refresh);
    },
    computed: {
        lotteryId() {
            throw 'Override this computed property:(lotteryId) by component.';
        },
        statisticsAsyncHandler() {
            // Override if necessary
            return service.getStatisticsAsync;
        },
        sumColorClassNameMappings(){
            return [
                { className: 'red', numbers: ['01','1','02','2','07','7','08','8','12','13','18','19','23','24','29','30','34','35','40','45','46'] },    
                { className: 'blue', numbers: ['03','3','04','4','09','9','10','14','15','20','25','26','31','36','37','41','42','47','48'] },    
                { className: 'green', numbers: ['05','5','06','6','11','16','17','21','22','27','28','32','33','38','39','43','44','49'] }
            ];
        } 
    }
};

export default statistics;