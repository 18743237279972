import QQ from '../views/QQ';

const routes = [{
    path: '/QQ',
    name: 'QQ_Layout',
    component: QQ.Layout,
    redirect: {
        name: 'QQ_Statistics'
    },
    children: [
        {
            path: 'Statistics',
            name: 'QQ_Statistics',
            component: QQ.Statistics
        },
        {
            path: 'Help',
            name: 'QQ_Help',
            component: QQ.Help
        },
        {
            path: 'Interface',
            name: 'QQ_Interface',
            component: QQ.Interface
        }
    ]
}];

export default routes;