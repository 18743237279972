<template>
  <div>
    <c-search :querys="querys" title="微信在线人数统计" @search="getStatisticsAsync"></c-search>
    <div class="container">
      <div class="history qq_online_history">
        <div class="qq_online scroll_qq_online">
          <div class="title">
            <ul>
              <li>序号</li>
              <li>采集返回时间</li>
              <li>微信在线人数</li>
              <li>同比上期</li>
            </ul>
          </div>
          <div class="list" v-for="data in statistics">
            <ul>
              <li>{{data.issueNo}}</li>
              <li>{{data.issueEndTime | formatDateTime('YYYY-MM-DD HH:mm:ss')}}</li>

              <li>{{(data.grabData || 0) | formatDigits}}</li>
              <li>
                <template v-if="data.diff > -1">
                  +{{data.diff | formatDigits}}
                  <i class="icon-dataup"></i>
                </template>
                <template v-else>
                  {{data.diff | formatDigits}}
                  <i class="icon-datadown"></i>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <c-pager v-model="pagination.pageNumber" :total="pagination.totalCount" :pageSize="pagination.pageSize" @change="pageChange"></c-pager>
      <c-feature></c-feature>
    </div>
  </div>
</template>

<script>
import { qqStatistics } from '../../mixins';

export default {
  mixins: [qqStatistics],
  computed: {
    lotteryId() {
      return 40;
    }
  }
}
</script>