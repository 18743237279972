import Jx11x5 from '../views/Jx11x5';

const routes = [{
    path: '/Jx11x5',
    name: 'Jx11x5_Layout',
    component: Jx11x5.Layout,
    redirect: {
        name: 'Jx11x5_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過Jx11x5沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'Jx11x5_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'Jx11x5_Statistics',
            component: Jx11x5.Statistics
        },
        {
            path: 'Interface',
            name: 'Jx11x5_Interface',
            component: Jx11x5.Interface
        }
    ]
}];

export default routes;