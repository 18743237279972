<template>
    <div>
      <div class="filter container">
        <div class="filter_title fl">接口调用</div>
      </div>
      <div class="article container">
        <p>本网站提供数据rest api接口，接口地址：<a :href="apiUrl" target="_blank">{{apiUrl}}</a></p>
        <p>请求方式：get</p>
        <p>返回类型：json</p>
        <div class="help_article">
          <p>返回结果说明：</p>
          <pre><code class="language-json">
      {
          "Code": "200",
          "Message": "success",
          "Success": true,
          "Result": [
              {
                  "IssueNo": 220308021,
                  "IssueTime": "2022-03-08 11:10:00",
                  "LottorNums": "01,02,03,04,05,06,07,08,09,10"
              }
          ]
      }
  </code></pre>
        </div>
        <div class="help_article">
          <p>说明:</p>
          <ol>
            <li>IssueNo: 期号</li>
            <li>IssueTime: 开奖时间</li>
            <li>LottorNums: 开奖结果</li>
          </ol>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { apiInterface } from '../../mixins';
  
  export default {
    mixins: [apiInterface],
    computed: {
      lotteryCode() {
        return 'OMPK10';
      }
    }
  }
  </script>