import OMPK10 from '../views/OMPK10';

const routes = [{
    path: '/OMPK10',
    name: 'OMPK10_Layout',
    component: OMPK10.Layout,
    redirect: {
        name: 'OMPK10_Statistics'
    },
    children: [
        {
            path: 'Help', // 因為其他有這頁不過OMPK10沒有，所以塞一個純粹跳轉用的            
            redirect: {
                name: 'OMPK10_Statistics'
            }
        },
        {
            path: 'Statistics',
            name: 'OMPK10_Statistics',
            component: OMPK10.Statistics
        },
        {
            path: 'Interface',
            name: 'OMPK10_Interface',
            component: OMPK10.Interface
        }
    ]
}];

export default routes;