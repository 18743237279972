import WeixinQQ from '../views/WeixinQQ';

const routes = [{
    path: '/WeixinQQ',
    name: 'WeixinQQ_Layout',
    component: WeixinQQ.Layout,
    redirect: {
        name: 'WeixinQQ_Statistics'
    },
    children: [
        {
            path: 'Statistics',
            name: 'WeixinQQ_Statistics',
            component: WeixinQQ.Statistics
        },
        {
            path: 'Help',
            name: 'WeixinQQ_Help',
            component: WeixinQQ.Help
        },
        {
            path: 'Interface',
            name: 'WeixinQQ_Interface',
            component: WeixinQQ.Interface
        }
    ]
}];

export default routes;